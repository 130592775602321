<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.05s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
            <h5 class="fw-bold text-primary text-uppercase">Our Services</h5>
            <h1 class="mb-0">ACS Tech Outsourcing service</h1>
        </div>
        <div class="row g-5">
            <div class="col-lg-6 col-md-6 col-sm-12 wow zoomIn" data-wow-delay="0.1s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="service-icon">
                        <i class="fab fa-android text-white "></i>
                    </div>
                    <h4 class="mb-3">Head hunting</h4>
                    <p class="m-0">Sourcing Vietnamese developer for onsite project. ACS will arrange short list candidate, co ordinate interview, advise salary and benefit, arange work visa off shore development center
                        ACS will operate customer project in our Vietnam office. ACS responsible for hiring and project management. Customer have full control of the team and benefit from 50% cost reduction and complete time.</p>
                    <a class="btn btn-lg btn-primary rounded" href="">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 wow zoomIn" data-wow-delay="0.2s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style="padding: 0 0 4.5rem 0;">
                    <div class="service-icon">
                        <i class="fa fa-chart-pie text-white"></i>
                    </div>
                    <h4 class="mb-3">Remote/ hybrid tech office</h4>
                    <p class="m-0">Customer having operation in Vietnam or Asian can quickly setup technical team . Serving ranging tech support, deployment, professional service, new version development.</p>
                    <a class="btn btn-lg btn-primary rounded" href="">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            
            <!-- <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="service-icon">
                        <i class="fa fa-code text-white"></i>
                    </div>
                    <h4 class="mb-3">Web Development</h4>
                    <p class="m-0">Amet just pain lorem kasd amet great sea stand them indeed lorem very pain but</p>
                    <a class="btn btn-lg btn-primary rounded" href="">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="service-icon">
                       <i class="fa fa-shield-alt text-white"></i>
                    </div>
                    <h4 class="mb-3">Apps Development</h4>
                    <p class="m-0">Amet just pain lorem kasd amet great sea stand them indeed lorem very pain but</p>
                    <a class="btn btn-lg btn-primary rounded" href="">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="service-icon">
                        <i class="fa fa-search text-white"></i>
                    </div>
                    <h4 class="mb-3">Telecommunications</h4>
                    <p class="m-0">Amet just pain lorem kasd amet great sea stand them indeed lorem very pain but</p>
                    <a class="btn btn-lg btn-primary rounded" href="">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                <div
                    class="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                    <h3 class="text-white mb-3">Call Us For Quote</h3>
                    <p class="text-white mb-3">Clita is very big kasd rebum but the real pain is just the pain is very
                        big</p>
                    <h2 class="text-white mb-0">0904 108 989</h2>
                </div>
            </div> -->
        </div>
    </div>
</div>


<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.05s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
            <h5 class="fw-bold text-primary text-uppercase">Technology Services</h5>
            <h1 class="mb-0"></h1>
        </div>

        <div class="row g-0">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
                <div class="bg-light rounded">
                    <div class="border-bottom py-4 px-5 mb-4">
                        <h4 class="text-primary mb-1">Brand developping</h4>
                        <small class="text-uppercase">Website design, company application building</small>
                    </div>
                    <div class="p-5 pt-0">
<!-- 
                        <h1 class="display-5 mb-3">
                            <small class="align-top" style="font-size: 22px; line-height: 45px;">$</small>49.00<small
                                class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                        </h1>
                         -->
                        <div class="d-flex justify-content-between mb-3">
                            <span>Standard SEO Design</span>
                            <i class="fa fa-check text-primary pt-1"></i>
                        </div>

                        <div class="d-flex justify-content-between mb-3">
                            <span>European, Korean, American,... modern design</span>
                            <i class="fa fa-check text-primary pt-1"></i>
                        </div>
                        <div class="d-flex justify-content-between mb-2">
                            <span>Cross-browser Support</span>
                            <i class="fa fa-check text-primary pt-1"></i>
                        </div>

                        <a href="quote" class="btn btn-primary py-2 px-4 mt-4">Order Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.05s">
                <div class="bg-white rounded shadow position-relative" style="z-index: 1;">
                    <div class="border-bottom py-4 px-5 mb-4">
                        <h4 class="text-primary mb-1">Outsourcing</h4>
                        <small class="text-uppercase">Website, Application, ERP, HRM, Camera AI, ...</small>
                    </div>
                    <div class="p-5 pt-0">
                        <!-- <h1 class="display-5 mb-3">
                            <small class="align-top" style="font-size: 22px; line-height: 45px;">$</small>99.00<small
                                class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                        </h1>
                        <div class="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i
                                class="fa fa-check text-primary pt-1"></i></div>
                        <div class="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i
                                class="fa fa-check text-primary pt-1"></i></div>
                        <div class="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i
                                class="fa fa-check text-primary pt-1"></i></div>
                        <div class="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i
                                class="fa fa-times text-danger pt-1"></i></div> -->
                        <a href="quote" class="btn btn-primary py-2 px-4 mt-4">Order Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
                <div class="bg-light rounded">
                    <div class="border-bottom py-4 px-5 mb-4">
                        <h4 class="text-primary mb-1">Products</h4>
                        <small class="text-uppercase">ICS, HRM, AI,...</small>
                    </div>
                    <div class="p-5 pt-0">
                        <!-- <h1 class="display-5 mb-3">
                            <small class="align-top" style="font-size: 22px; line-height: 45px;">$</small>149.00<small
                                class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                        </h1> -->
                        <div class="d-flex justify-content-between mb-3"><span>Internal communication software</span><i
                                class="fa fa-check text-primary pt-1"></i></div>
                        <div class="d-flex justify-content-between mb-3"><span>Human resource management system</span><i
                                class="fa fa-check text-primary pt-1"></i></div>
                        <div class="d-flex justify-content-between mb-3"><span>Camera AI solutions</span><i
                                class="fa fa-check text-primary pt-1"></i></div>
                        <div class="d-flex justify-content-between mb-2"><span>ChatBot</span><i
                                class="fa fa-check text-primary pt-1"></i></div>
                        <div class="d-flex justify-content-between mb-2"><span>VoiceBot</span><i
                            class="fa fa-check text-primary pt-1"></i></div>
                        
                        <a href="quote" class="btn btn-primary py-2 px-4 mt-4">Order Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>