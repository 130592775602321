import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DATA_SERVICES } from 'src/assets/lib/data/services';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss'],
})
export class ServiceDetailComponent implements OnInit {
  public serviceDetail: ServiceType = {
    title: '',
    slug: '',
    description: '',
    time: '',
    html: '',
  };
  public dataServices: any = DATA_SERVICES;
  public selectedLanguage: string = 'vi';

  public listImages = [
    'assets/images/website-design.jpg',
    'assets/images/apps.jpg',
    'assets/images/outsource.jpg',
    'assets/images/ai-tranning.jpg',
    'assets/images/hrm.jpg',
  ];

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadServiceDetail();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
      this.loadServiceDetail();
    });
  }

  getRandBanner() {
    const result: string[] = [];
    while (result.length < 3) {
      const index = Math.floor(Math.random() * this.listImages.length);
      if (!result.find((c) => c === this.listImages[index])) {
        result.push(this.listImages[index]);
      }
    }
    return result;
  }

  loadServiceDetail() {
    this.route.params.subscribe((params) => {
      this.serviceDetail = this.dataServices[this.selectedLanguage].find(
        (c: ServiceType) => c.slug === params['slug']
      );
    });
  }
}
