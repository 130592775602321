<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.05s">
  <div class="container py-5">
    <div class="row g-5">
      <div class="col-lg-7">
        <div class="section-title position-relative pb-3 mb-5">
          <h5 class="fw-bold text-primary text-uppercase">
            {{ "quote.title" | translate }}
          </h5>
          <h1 class="mb-0">
            {{ "quote.description" | translate }}
          </h1>
        </div>
        <div class="row gx-3">
          <div class="col-sm-6 wow zoomIn" data-wow-delay="0.1s">
            <h5 class="mb-4">
              <i class="fa fa-reply text-primary me-3"></i
              >{{ "quote.reply" | translate }}
            </h5>
          </div>
          <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
            <h5 class="mb-4">
              <i class="fa fa-phone-alt text-primary me-3"></i
              >{{ "quote.support" | translate }}
            </h5>
          </div>
        </div>
        <p class="mb-4" [innerHTML]="'quote.subtitle' | translate"></p>
        <div
          class="d-flex align-items-center mt-2 wow zoomIn"
          data-wow-delay="0.3s"
        >
          <div
            class="bg-primary d-flex align-items-center justify-content-center rounded"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-phone-alt text-white"></i>
          </div>
          <div class="ps-4">
            <h5 class="mb-2">{{ "quote.call" | translate }}</h5>
            <h4 class="text-primary mb-0">{{ "phone.number" | translate }}</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div
          class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
          data-wow-delay="0.5s"
        >
          <form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
            <div class="row g-3">
              <div class="col-xl-12">
                <input
                  type="text"
                  class="form-control bg-light border-0"
                  placeholder="{{ 'contact.form.name' | translate }}"
                  style="height: 55px"
                  formControlName="fullName"
                />
              </div>
              <div class="col-12">
                <input
                  type="email"
                  class="form-control bg-light border-0"
                  placeholder="{{ 'contact.form.email' | translate }}"
                  style="height: 55px"
                  formControlName="email"
                />
              </div>
              <div class="col-12">
                <select
                  class="form-select bg-light border-0"
                  style="height: 55px"
                  formControlName="subject"
                >
                  <option selected>
                    {{ "quote.form.select" | translate }}
                  </option>
                  <option *ngFor="let item of getSelectData()" [value]="item">
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="col-12">
                <textarea
                  class="form-control bg-light border-0"
                  rows="3"
                  placeholder="{{ 'contact.form.message' | translate }}"
                  formControlName="message"
                ></textarea>
              </div>
              <div class="col-12">
                <button class="btn btn-dark w-100 py-3" type="submit">
                  {{ "quote.form.submit" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
