import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { httpTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { ListJobComponent } from './list-job/list-job.component';
import { ProductComponent } from './product/product.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ListJobComponent, ProductComponent],
  exports: [ListJobComponent, ProductComponent],
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
})
export class ComponentsModule {}
