<!-- Footer Start -->
<link
  rel="stylesheet prefetch"
  href="https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css"
/>

<div
  class="container-fluid bg-dark text-light mt-5 wow fadeInUp"
  data-wow-delay="0.05s"
>
  <div class="container">
    <div class="row gx-5">
      <div class="col-lg-4 col-md-6 footer-about">
        <div
          class="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4"
        >
          <a href="/" class="navbar-brand">
            <img src="assets/img/logo.png" alt="" class="img-logo" />
          </a>
          <p class="mt-3 mb-4">{{ "footer.description" | translate }}</p>

          <div class="stars">
            <form action="">
              <input class="star star-5" id="star-5" type="radio" name="star" />
              <label class="star star-5" for="star-5"></label>
              <input class="star star-4" id="star-4" type="radio" name="star" />
              <label class="star star-4" for="star-4"></label>
              <input class="star star-3" id="star-3" type="radio" name="star" />
              <label class="star star-3" for="star-3"></label>
              <input class="star star-2" id="star-2" type="radio" name="star" />
              <label class="star star-2" for="star-2"></label>
              <input class="star star-1" id="star-1" type="radio" name="star" />
              <label class="star star-1" for="star-1"></label>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-6">
        <div class="row gx-5">
          <div class="col-lg-4 col-md-12 pt-5 mb-5">
            <div
              class="section-title section-title-sm position-relative pb-3 mb-4"
            >
              <h3 class="text-light mb-0">
                {{ "footer.title.address" | translate }}
              </h3>
            </div>
            <div class="d-flex mb-2">
              <i class="bi bi-geo-alt text-primary me-2"></i>
              <p class="mb-0">
                {{ "footer.address" | translate }}
              </p>
            </div>
            <div class="d-flex mb-2">
              <i class="bi bi-envelope-open text-primary me-2"></i>
              <p class="mb-0">{{ "email.address" | translate }}</p>
            </div>
            <div class="d-flex mb-2">
              <i class="bi bi-telephone text-primary me-2"></i>
              <p class="mb-0">{{ "phone.number" | translate }}</p>
            </div>

            <!-- <div class="d-flex mt-4">
              <a class="btn btn-primary btn-square me-2" href="#"
                ><i class="fab fa-twitter fw-normal"></i
              ></a>
              <a class="btn btn-primary btn-square me-2" href="#"
                ><i class="fab fa-facebook-f fw-normal"></i
              ></a>
              <a class="btn btn-primary btn-square me-2" href="#"
                ><i class="fab fa-linkedin-in fw-normal"></i
              ></a>
              <a class="btn btn-primary btn-square" href="#"
                ><i class="fab fa-instagram fw-normal"></i
              ></a>
            </div> -->
          </div>

          <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
            <div
              class="section-title section-title-sm position-relative pb-3 mb-4"
            >
              <h3 class="text-light mb-0">
                {{ "footer.title.link" | translate }}
              </h3>
            </div>
            <div class="link-animated d-flex flex-column justify-content-start">
              <a class="text-light mb-2" href="/"
                ><i class="bi bi-arrow-right text-primary me-2"></i
                >{{ "home" | translate }}</a
              >
              <a class="text-light mb-2" href="/about"
                ><i class="bi bi-arrow-right text-primary me-2"></i
                >{{ "about" | translate }}</a
              >
              <a class="text-light mb-2" href="/services"
                ><i class="bi bi-arrow-right text-primary me-2"></i
                >{{ "services" | translate }}</a
              >
              <a class="text-light" href="/contact"
                ><i class="bi bi-arrow-right text-primary me-2"></i
                >{{ "contact" | translate }}</a
              >
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
            <div
              class="section-title section-title-sm position-relative pb-3 mb-4"
            >
              <h3 class="text-light mb-0">Popular Links</h3>
            </div>
            <div class="link-animated d-flex flex-column justify-content-start">
              <a class="text-light mb-2" href="#"
                ><i class="bi bi-arrow-right text-primary me-2"></i>Home</a
              >
              <a class="text-light mb-2" href="#"
                ><i class="bi bi-arrow-right text-primary me-2"></i>About Us</a
              >
              <a class="text-light mb-2" href="#"
                ><i class="bi bi-arrow-right text-primary me-2"></i>Our
                Services</a
              >
              <a class="text-light mb-2" href="#"
                ><i class="bi bi-arrow-right text-primary me-2"></i>Meet The
                Team</a
              >
              <a class="text-light mb-2" href="#"
                ><i class="bi bi-arrow-right text-primary me-2"></i>Latest
                Blog</a
              >
              <a class="text-light" href="#"
                ><i class="bi bi-arrow-right text-primary me-2"></i>Contact
                Us</a
              >
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid text-white" style="background: #061429">
  <div class="container text-center">
    <div class="row justify-content-end">
      <div class="col-lg-8 col-md-6">
        <div
          class="d-flex align-items-center justify-content-center"
          style="height: 75px"
        >
          <p class="mb-0">
            &copy; 2023 -
            <a class="text-white border-bottom" href="#">ACS Website</a>. All
            Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->

<!-- Back to Top -->
<a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
  ><i class="bi bi-arrow-up"></i
></a>
