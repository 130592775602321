<div class="container-fluid pt-5 wow fadeInUp" data-wow-delay="0.05s">
  <div class="container pt-5">
    <div
      class="section-title text-center position-relative pb-3 mb-4 mx-auto"
      style="max-width: 600px"
    >
      <!-- <h5 class="fw-bold text-primary text-uppercase">Testimonial</h5> -->
      <h2 class="mb-0 text-uppercase">{{ "comment.title" | translate }}</h2>
    </div>
    <div class="testimonial-carousel wow fadeInUp" data-wow-delay="0.15s">
      <owl-carousel-o [options]="personOption">
        <ng-template carouselSlide *ngFor="let customer of getPersonComment()">
          <div class="testimonial-carousel bg-light my-4">
            <div class="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
              <img
                class="img-fluid rounded"
                src="{{ customer.avatar }}"
                style="width: 60px; height: 60px"
              />
              <div class="ps-4">
                <h4 class="text-primary mb-1">{{ customer.username }}</h4>
                <small class="text-uppercase">{{ customer.position }}</small>
              </div>
            </div>
            <div class="pt-4 pb-5 px-5 line-clamp-3">
              {{ customer.comment }}
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
