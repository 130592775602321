<!-- Topbar Start -->
<!-- <div class="container-fluid bg-dark px-5 d-none d-lg-block">
    <div class="row gx-0">
        <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div class="d-inline-flex align-items-center" style="height: 45px;">
                <small class="me-3 text-light"><i class="fa fa-map-marker-alt me-2"></i>26 Lieu Giai, Ba Dinh Dist, Hanoi Capital</small>
                <small class="me-3 text-light"><i class="fa fa-phone-alt me-2"></i>(+84) 0904 108 989</small>
                <small class="text-light"><i class="fa fa-envelope-open me-2"></i>hata.acs@gmail.com</small>
            </div>
        </div>
        <div class="col-lg-4 text-center text-lg-end">
            <div class="d-inline-flex align-items-center" style="height: 45px;">
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-twitter fw-normal"></i></a>
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-facebook-f fw-normal"></i></a>
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-linkedin-in fw-normal"></i></a>
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i class="fab fa-instagram fw-normal"></i></a>
                <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href=""><i class="fab fa-youtube fw-normal"></i></a>
            </div>
        </div>
    </div>
</div> -->

<!-- Topbar End -->
<!-- Navbar & Carousel Start -->
<div class="container-fluid position-relative p-0 format-navbar">
  <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
    <a href="/" class="navbar-brand p-0">
      <img src="assets/img/logo.png" alt="" class="img-logo">
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span class="fa fa-bars"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav ms-auto py-0">
        <a
          routerLink="/"
          class="nav-item nav-link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >{{ "home" | translate }}</a
        >
        <a
          routerLink="/about"
          class="nav-item nav-link"
          routerLinkActive="active"
          >{{ "about" | translate }}</a
        >
        <a
          routerLink="/services"
          class="nav-item nav-link"
          routerLinkActive="active"
          >{{ "services" | translate }}</a
        >
        <!-- <div class="nav-item dropdown">
                    <div class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Blog</div>
                    <div class="dropdown-menu m-0">
                        <a routerLink="/blog" class="dropdown-item">Blog Grid</a>
                        <a routerLink="/detail" class="dropdown-item">Blog Detail</a>
                    </div>
                </div> -->
        <a
          routerLink="contact"
          class="nav-item nav-link"
          routerLinkActive="active"
          >{{ "contact" | translate }}</a
        >
        <div class="nav-item dropdown cursor-pointer">
          <div class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
            {{ "lang" | translate }}
          </div>
          <div class="dropdown-menu m-0">
            <!-- <a href="price" class="dropdown-item">Pricing Plan</a> -->
            <p (click)="switchLang('vi')" class="dropdown-item cursor-pointer">
              🇻🇳 Tiếng Việt
            </p>
            <!-- <a href="team" class="dropdown-item">Team Members</a> -->
            <p (click)="switchLang('en')" class="dropdown-item cursor-pointer">🇬🇧 English</p>
            <!-- <a href="quote" class="dropdown-item">Free Quote</a> -->
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn text-primary ms-3"
        data-bs-toggle="modal"
        data-bs-target="#searchModal"
      >
        <i class="fa fa-search"></i>
      </button>
    </div>
  </nav>
</div>
<!-- Navbar & Carousel End -->
