import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DATA_SERVICES } from 'src/assets/lib/data/services';
import { getLocalLanguage } from 'src/assets/lib/sources/localStorageLanguage';

@Component({
  selector: 'app-list-job',
  templateUrl: './list-job.component.html',
  styleUrls: ['./list-job.component.scss'],
})
export class ListJobComponent implements OnInit {
  public selectedLanguage: string = getLocalLanguage();

  public listJob: any = DATA_SERVICES;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  getFullYear() {
    return new Date().getFullYear() - 2022;
  }

  getListData() {
    return this.listJob[this.selectedLanguage];
  }
}
