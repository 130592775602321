import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { getLocalLanguage } from 'src/assets/lib/sources/localStorageLanguage';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  personOption: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 200,
    autoplay: true,
    autoplayTimeout: 4000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };

  public listBanner: any = {
    en: [
      {
        id: 1,
        image: 'assets/img/banner.png',
        title: 'Convergence Of Technology Quintessence',
        description: 'ACS Company',
      },
      {
        id: 2,
        image: 'assets/img/carousel-1.jpg',
        title: 'Convergence Of Technology Quintessence',
        description: 'AClass Software',
      },
    ],
    vi: [
      {
        image: 'assets/img/banner.png',
        title: 'Convergence Of Technology Quintessence',
        description: 'ACS Company',
        active: true,
      },
      {
        image: 'assets/banner/banner1.jpg',
        title: '',
        description: '',
      },
      {
        image: 'assets/banner/banner2.jpg',
        title: '',
        description: '',
      },
    ],
  };

  public selectedLanguage: string = getLocalLanguage();

  public activeIndex: number = 0;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  getBanner() {
    return this.listBanner[this.selectedLanguage];
  }

  getActiveClass(index: number): string {
    return index === this.activeIndex ? 'active' : '';
  }

  nextSlide(): void {
    this.activeIndex = (this.activeIndex + 1) % this.getBanner.length;
  }

  prevSlide(): void {
    this.activeIndex =
      (this.activeIndex - 1 + this.getBanner.length) % this.getBanner.length;
  }
}
