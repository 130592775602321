import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public contactForm = this.formBuilder.group({
    fullName: [''],
    email: [''],
    subject: [''],
    message: [''],
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {}

  onSubmit() {
    const value = this.contactForm.value;
    const body = `
      <p>Họ tên khách hàng: ${value.fullName}</p>
      <p>Tiêu đề: ${value.subject}</p>
      <p>Email gửi: ${value.email}</p>
      <p>Nội dung: ${value.message}</p>
    `;
    const mailToLink = `mailto:aclasssoftware22@gmail.com.com?subject=${encodeURIComponent(
      value.subject as string
    )}&body=${encodeURIComponent(body)}`;
    console.log('------------value', mailToLink);
    window.location.href = mailToLink;
  }
}
