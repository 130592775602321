<div class="container-fluid wow fadeInUp">
  <div class="container">
    <div
      id="header-carousel"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div
          *ngFor="let banner of getBanner(); let i = index"
          class="carousel-item carousel-side"
          [class.active]="i === activeIndex"
        >
          <img
            class="w-100 banner-cover"
            src="{{ banner.image }}"
            alt="Image"
          />
          <div
            class="carousel-caption d-flex flex-column align-items-center justify-content-center"
          >
            <div class="p-3" style="max-width: 900px">
              <h5 class="text-white text-uppercase mb-3 animated slideInDown">
                {{ banner.title }}
              </h5>
              <h1 class="display-1 text-white mb-md-4 animated zoomIn">
                {{ banner.description }}
              </h1>
              <a
                *ngIf="banner.active"
                href="quote"
                class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                >{{ "quote" | translate }}</a
              >
              <a
                *ngIf="banner.active"
                href="contact"
                class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                >{{ "contact" | translate }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="prev"
        (click)="prevSlide()"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="next"
        (click)="nextSlide()"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>

<!-- Full Screen Search Start -->
<div class="modal fade" id="searchModal" tabindex="-1">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content" style="background: rgba(9, 30, 62, 0.7)">
      <div class="modal-header border-0">
        <button
          type="button"
          class="btn bg-white btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body d-flex align-items-center justify-content-center">
        <div class="input-group" style="max-width: 600px">
          <input
            type="text"
            class="form-control bg-transparent border-primary p-3"
            placeholder="Type search keyword"
          />
          <button class="btn btn-primary px-4">
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Full Screen Search End -->
<!-- Facts Start -->
<!-- <div class="container-fluid facts py-5 pt-lg-0">
  <div class="container py-5 pt-lg-0">
    <div class="row gx-0">
      <div class="col-lg-4 wow zoomIn" data-wow-delay="0.06s">
        <div
          class="bg-primary shadow d-flex align-items-center justify-content-center p-4"
          style="height: 150px"
        >
          <div
            class="bg-white d-flex align-items-center justify-content-center rounded mb-2"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-users text-primary"></i>
          </div>
          <div class="ps-4">
            <h5 class="text-white mb-0">Happy Clients</h5>
            <h1 class="text-white mb-0" data-toggle="counter">188</h1>
          </div>
        </div>
      </div>
      <div class="col-lg-4 wow zoomIn" data-wow-delay="0.09s">
        <div
          class="bg-light shadow d-flex align-items-center justify-content-center p-4"
          style="height: 150px"
        >
          <div
            class="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-check text-white"></i>
          </div>
          <div class="ps-4">
            <h5 class="text-primary mb-0">Projects Done</h5>
            <h1 class="mb-0" data-toggle="counter-up">165</h1>
          </div>
        </div>
      </div>
      <div class="col-lg-4 wow zoomIn" data-wow-delay="0.12s">
        <div
          class="bg-primary shadow d-flex align-items-center justify-content-center p-4"
          style="height: 150px"
        >
          <div
            class="bg-white d-flex align-items-center justify-content-center rounded mb-2"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-award text-primary"></i>
          </div>
          <div class="ps-4">
            <h5 class="text-white mb-0">Win Awards</h5>
            <h1 class="text-white mb-0" data-toggle="counter-up">34</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<app-list-job></app-list-job>

<!-- Facts Start -->
<!-- <app-about-us></app-about-us> -->

<!-- Product Start -->
<app-product-component></app-product-component>
<!-- Product Start -->

<!-- Features Start -->
<!-- <app-why-choose></app-why-choose> -->
<!-- Features Start -->

<!-- Service Start -->
<!-- <app-our-services></app-our-services> -->
<!-- Service End -->

<!-- Pricing Plan Start -->
<!-- <app-pricing></app-pricing> -->
<!-- Pricing Plan End -->

<!-- Testimonial Start -->
<person-component></person-component>
<!-- Testimonial End -->

<!-- Team Start -->
<!-- <app-members></app-members> -->
<!-- Team End -->

<!-- Quote Start -->
<app-request></app-request>
<!-- Quote End -->

<!-- <logo-component></logo-component> -->
