<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.03s">
  <div class="container py-5">
    <div
      class="section-title text-center position-relative pb-3 mb-5 mx-auto"
      style="max-width: 600px"
    >
      <!-- <h5 class="fw-bold text-primary text-uppercase">Latest Blog</h5> -->
      <h2 class="mb-0 text-uppercase">{{ "product.title" | translate }}</h2>
    </div>
    <div class="row row-cols-lg-3 g-3">
      <div *ngFor="let product of getListProduct()">
        <div class="col mb-1 wow slideInUp shadow-black" data-wow-delay="0.06s">
          <div class="blog-item bg-light rounded overflow-hidden">
            <div class="blog-img position-relative overflow-hidden">
              <img class="img-fluid img-fix" src="{{ product.image }}" alt="" />
              <a
                class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                href=""
                >{{ product.tag }}</a
              >
            </div>
            <div class="p-4 product-detail">
              <div class="d-flex mb-3">
                <small class="me-3"
                  ><i class="far fa-user text-primary me-2"></i
                  >{{ product.owner }}</small
                >
                <small
                  ><i class="far fa-calendar-alt text-primary me-2"></i
                  >{{ product.time }}</small
                >
              </div>
              <h4 class="mb-3 title-content">{{ product.title }}</h4>
              <p class="line-clamp-2">{{ product.subtitle }}</p>
              <a class="text-uppercase" href="{{ product.url }}" target="_blank"
                >{{ "read.more" | translate }} <i class="bi bi-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
