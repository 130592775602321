import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { getLocalLanguage } from 'src/assets/lib/sources/localStorageLanguage';
@Component({
  selector: 'person-component',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class PersonComponent implements OnInit {
  personOption: OwlOptions = {
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    loop: true,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  };

  public personComment: any = {
    en: [
      {
        avatar: 'assets/img/testimonial-1.jpg',
        username: 'Lyly',
        position: 'Profession',
        comment:
          'The pain and the labor, let it be just, but it is but. I am sorry, but the pain will stop, so I will make them work hard',
      },
      {
        avatar: 'assets/img/testimonial-2.jpg',
        username: 'Jacol',
        position: 'Profession',
        comment:
          'You’ll never be brave if you do not get hurt. You’ll never learn if you do not make mistakes. You’ll never be successful ',
      },
      {
        avatar: 'assets/img/testimonial-3.jpg',
        username: 'David',
        position: 'Profession',
        comment:
          'I’ll always choose a lazy person’s job… because he’ll find an easy way to do it.so many',
      },
      {
        avatar: 'assets/img/testimonial-4.jpg',
        username: 'Emyle',
        position: 'Profession',
        comment:
          'Sow a thought, and you reap an act. Sow an act, and you reap a habit. Sow a habit, and you reap a character.',
      },
    ],
    vi: [
      {
        avatar: 'assets/img/testimonial-1.jpg',
        username: 'Lyly',
        position: 'Profession',
        comment:
          'The pain and the labor, let it be just, but it is but. I am sorry, but the pain will stop, so I will make them work hard',
      },
      {
        avatar: 'assets/img/testimonial-2.jpg',
        username: 'Jacol',
        position: 'Profession',
        comment:
          'You’ll never be brave if you do not get hurt. You’ll never learn if you do not make mistakes. You’ll never be successful ',
      },
      {
        avatar: 'assets/img/testimonial-3.jpg',
        username: 'David',
        position: 'Profession',
        comment:
          'I’ll always choose a lazy person’s job… because he’ll find an easy way to do it.so many',
      },
      {
        avatar: 'assets/img/testimonial-4.jpg',
        username: 'Emyle',
        position: 'Profession',
        comment:
          'Sow a thought, and you reap an act. Sow an act, and you reap a habit. Sow a habit, and you reap a character.',
      },
    ],
  };

  public selectedLanguage: string = getLocalLanguage();

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  getPersonComment() {
    return this.personComment[this.selectedLanguage];
  }
}
