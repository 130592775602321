import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  public aboutContent: any = {
    en: `AClass Software Joint Stock Company (AClass Software), abbreviated as ACS, was established in June 2022. We provide services: website design, management software development, human resources software, AI, ERM, HRM,... .
    <p class='mt-2 mb-0'>ACS provides the following services:</p>
    <p class='mb-0'>- Domain registration</p>
    <p class='mb-0'>- Website design</p>
    <p class='mb-0'>- Web-hosting</p>
    <p class='mb-0'>- Promotion services: SEO, Facebook, Zalo, Google advertising</p>
    <p class="mt-2">We always aim to build solutions for businesses in digital transformations with to goals: increasing revenue and saving costs. At the same time contributing a part to the development and the prosperity of the conntry's technology industry. ACS is a Global IT Consulting & Software development company that embraces difficulty, creativity & unicity. With a clear vision and passionate young workforce, ACS has created atmosphere that allows the brightest minds to strive for technological perfection & audacious business ideas accomplishment.</p>`,
    vi: `Công ty Cổ Phần Phần Mềm AClass (AClass Software) viết tắt là ACS được thành lập từ  06/2022. Chúng tôi cung cấp các dịch vụ: thiết kế website, xây dựng phần mềm quản lý, phần mềm nhân lực, AI, ERM, HRM,... . 
    <p class='mt-2 mb-0'>ACS cung cấp các dịch vụ sau:</p>
    <p class='mb-0'>- Đăng ký tên miền</p>
    <p class='mb-0'>- Thiết kế website</p>
    <p class='mb-0'>- Web-hosting</p>
    <p class='mb-0'>- Dịch vụ quảng bá: SEO, quảng cáo Facebook, Zalo, Google</p>
    <p class="mt-2">Chúng tôi luôn hướng tới xây dựng các giải pháp cho doanh nghiệp trong chuyển đổi số với mục tiêu: tăng doanh thu và tiết kiệm chi phí. Đồng thời đóng góp một phần vào sự phát triển và thịnh vượng của ngành công nghệ nước ta. ACS là công ty phát triển Phần mềm & Tư vấn CNTT Toàn cầu, chấp nhận khó khăn, sáng tạo và độc đáo. Với tầm nhìn rõ ràng và lực lượng lao động trẻ đầy nhiệt huyết, ACS đã tạo ra bầu không khí cho phép những bộ óc thông minh nhất nỗ lực hoàn thiện công nghệ và hoàn thành những ý tưởng kinh doanh táo bạo.</p>
    `,
  };

  public selectedLanguage: string = 'vi';

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
      this.getDescription();
    });
  }

  getDescription() {
    return this.aboutContent[this.selectedLanguage];
  }
}
