<div class="container-fluid position-relative p-0">
  <div
    class="container-fluid bg-primary py-5 bg-contact"
    style="margin-bottom: 90px"
  >
    <div class="row py-5">
      <div class="col-12 pt-lg-5 mt-lg-5 text-center">
        <h1 class="display-4 text-white animated zoomIn">
          {{ "contact" | translate }}
        </h1>
        <a href="/" class="h5 text-white">{{ "home" | translate }}</a>
        <i class="far fa-circle text-white px-2"></i>
        <a href="/contact" class="h5 text-white">{{ "contact" | translate }}</a>
      </div>
    </div>
  </div>
</div>
<!-- Full Screen Search Start -->
<div class="modal fade" id="searchModal" tabindex="-1">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content" style="background: rgba(9, 30, 62, 0.7)">
      <div class="modal-header border-0">
        <button
          type="button"
          class="btn bg-white btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body d-flex align-items-center justify-content-center">
        <div class="input-group" style="max-width: 600px">
          <input
            type="text"
            class="form-control bg-transparent border-primary p-3"
            placeholder="Type search keyword"
          />
          <button class="btn btn-primary px-4">
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Full Screen Search End -->

<!-- Contact Start -->
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.05s">
  <div class="container py-5">
    <div
      class="section-title text-center position-relative pb-3 mb-5 mx-auto"
      style="max-width: 600px"
    >
      <h5 class="fw-bold text-primary text-uppercase">
        {{ "contact.title" | translate }}
      </h5>
      <h1 class="mb-0">{{ "contact.description" | translate }}</h1>
    </div>
    <div class="row g-5 mb-5">
      <div class="col-lg-4">
        <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
          <div
            class="bg-primary d-flex align-items-center justify-content-center rounded"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-phone-alt text-white"></i>
          </div>
          <div class="ps-4">
            <h5 class="mb-2">{{ "contact.call" | translate }}</h5>
            <h4 class="text-primary mb-0">{{ "phone.number" | translate }}</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.2s">
          <div
            class="bg-primary d-flex align-items-center justify-content-center rounded"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-envelope-open text-white"></i>
          </div>
          <div class="ps-4">
            <h5 class="mb-2">{{ "contact.email" | translate }}</h5>
            <h4 class="text-primary mb-0">{{ "email.address" | translate }}</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.3s">
          <div
            class="bg-primary d-flex align-items-center justify-content-center rounded"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-map-marker-alt text-white"></i>
          </div>
          <div class="ps-4">
            <h5 class="mb-2">{{ "contact.address" | translate }}</h5>
            <h4 class="text-primary mb-0">
              {{ "footer.address" | translate }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row g-5">
      <div class="col-lg-6 wow slideInUp" data-wow-delay="0.1s">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
          <div class="row g-3">
            <div class="col-md-6">
              <input
                type="text"
                class="form-control border-0 bg-light px-4"
                placeholder="{{ 'contact.form.name' | translate }}"
                style="height: 55px"
                formControlName="fullName"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control border-0 bg-light px-4"
                placeholder="{{ 'contact.form.email' | translate }}"
                style="height: 55px"
                formControlName="email"
              />
            </div>
            <div class="col-12">
              <input
                type="text"
                class="form-control border-0 bg-light px-4"
                placeholder="{{ 'contact.form.subject' | translate }}"
                style="height: 55px"
                formControlName="subject"
              />
            </div>
            <div class="col-12">
              <textarea
                class="form-control border-0 bg-light px-4 py-3"
                rows="4"
                placeholder="{{ 'contact.form.message' | translate }}"
                formControlName="message"
              ></textarea>
            </div>
            <div class="col-12">
              <button class="btn btn-primary w-100 py-3" type="submit">
                {{ "contact.form.submit" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.9347618958445!2d105.81220921501287!3d21.035296185994806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab12df66dbf7%3A0xec85aa9e688e057a!2zMjYgUC4gTGnhu4V1IEdpYWksIEPhu5FuZyBW4buLLCBCYSDEkMOsbmgsIEjDoCBO4buZaSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1663168519715!5m2!1svi!2s"
          width="600"
          height="350"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </div>
</div>
<!-- Contact End -->

<!-- <logo-component></logo-component> -->
