<div class="container-fluid pt-5 wow fadeInUp" data-wow-delay="0.03s">
  <div class="container pt-5">
    <div
      class="section-title text-center position-relative pb-3 mb-5 mx-auto"
      style="max-width: 600px"
    >
      <!-- <h5 class="fw-bold text-primary text-uppercase">Latest Blog</h5> -->
      <h2 class="mb-0 text-uppercase">{{ "service.title" | translate }}</h2>
    </div>
    <div class="row row-cols-lg-3 g-1">
      <div *ngFor="let item of getListData()">
        <div class="col mb-0.5 wow slideInUp" data-wow-delay="item.time">
          <div class="blog-item bg-light rounded overflow-hidden">
            <div class="p-4">
              <h4 class="mb-3">{{ item.title }}</h4>
              <p class="line-clamp-4">{{ item.description }}</p>
              <a class="text-uppercase" [routerLink]="['/services', item.slug]"
                >{{ "read.more" | translate }} <i class="bi bi-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
