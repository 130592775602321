import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  getLocalLanguage,
  setLocalLanguage,
} from 'src/assets/lib/sources/localStorageLanguage';

@Component({
  selector: 'header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.scss'],
})
export class HeaderComponentComponent implements OnInit {
  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'vi']);
    translate.setDefaultLang(getLocalLanguage());
  }

  switchLang(lang: string) {
    setLocalLanguage(lang);
    this.translate.use(lang);
  }

  ngOnInit(): void {}
}
