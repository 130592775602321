import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})
export class RequestComponent implements OnInit {
  public requestForm = this.formBuilder.group({
    fullName: [''],
    email: [''],
    subject: [''],
    message: [''],
  });

  public dataSelect: any = {
    en: ['Headhunting', 'Outsourcing', 'Website Design', 'Develop AI'],
    vi: [
      'Tìm kiếm ứng viên',
      'Gia công phần mềm',
      'Thiết kế website',
      'Phát triển AI',
    ],
  };

  public selectedLanguage: string = 'vi';

  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
      this.getSelectData();
    });
  }

  getSelectData() {
    return this.dataSelect[this.selectedLanguage];
  }

  onSubmit() {
    const value = this.requestForm.value;
    const body = `
      <p>Họ tên khách hàng: ${value.fullName}</p>
      <p>Tiêu đề: ${value.subject}</p>
      <p>Email gửi: ${value.email}</p>
      <p>Nội dung: ${value.message}</p>
    `;
    const mailToLink = `mailto:aclasssoftware22@gmail.com.com?subject=${encodeURIComponent(
      value.subject as string
    )}&body=${encodeURIComponent(body)}`;
    console.log('------------value', mailToLink);
    window.location.href = mailToLink;
  }
}
