<div
  class="container-fluid bg-primary py-5 bg-about"
  style="margin-bottom: 90px"
>
  <div class="row py-5">
    <div class="col-12 pt-lg-5 mt-lg-5 text-center">
      <h1 class="display-4 text-white animated zoomIn">{{"about"|translate}}</h1>
      <a href="/" class="h5 text-white">{{"home"|translate}}</a>
      <i class="far fa-circle text-white px-2"></i>
      <a href="/about" class="h5 text-white">{{"about"|translate}}</a>
    </div>
  </div>
</div>
<!-- Full Screen Search Start -->
<div class="modal fade" id="searchModal" tabindex="-1">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content" style="background: rgba(9, 30, 62, 0.7)">
      <div class="modal-header border-0">
        <button
          type="button"
          class="btn bg-white btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body d-flex align-items-center justify-content-center">
        <div class="input-group" style="max-width: 600px">
          <input
            type="text"
            class="form-control bg-transparent border-primary p-3"
            placeholder="Type search keyword"
          />
          <button class="btn btn-primary px-4">
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Full Screen Search End -->

<!-- About Start -->
<app-about-us></app-about-us>
<!-- About End -->

<!-- Team Start -->
<!-- <app-members></app-members> -->
<!-- Team End -->

<!-- <logo-component></logo-component> -->
