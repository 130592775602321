import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { getLocalLanguage } from 'src/assets/lib/sources/localStorageLanguage';

@Component({
  selector: 'app-product-component',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  public listProduct: any = {
    en: [
      {
        image: 'assets/images/website-1.png',
        tag: 'VietClever',
        owner: 'VietClever',
        time: '11/2022',
        title: 'Office Website',
        subtitle:
          'Website introduces offices for rent, virtual offices, coworking spaces, and office packages',
        url: 'https://vietclever.vn',
      },
      {
        image: 'assets/images/website-2.png',
        tag: 'ldo',
        owner: 'ldo.vn',
        time: '06/2022',
        title: 'Recruit Website',
        subtitle: 'Website connects candidates and employers',
        url: 'http://ldo.vn',
      },
      {
        image: 'assets/images/website-3.png',
        tag: 'Belifood',
        owner: 'Beli',
        time: '10/2022',
        title: 'E-commerce website',
        subtitle:
          'Website trading agricultural products and health products',
        url: 'https://matongbeli.com',
      },
    ],
    vi: [
      {
        image: 'assets/images/website-1.png',
        tag: 'VietClever',
        owner: 'VietClever',
        time: '11/2022',
        title: 'Website cho thuê văn phòng',
        subtitle:
          'Website giới thiệu văn phòng cho thuê, văn phòng ảo, coworking space, văn phòng chọn gói,',
        url: 'https://vietclever.vn',
      },
      {
        image: 'assets/images/website-2.png',
        tag: 'ldo',
        owner: 'ldo.vn',
        time: '06/2022',
        title: 'Website tuyển dụng',
        subtitle: 'Website kết nối giữa ứng viên và các nhà tuyển dụng',
        url: 'http://ldo.vn',
      },
      {
        image: 'assets/images/website-3.png',
        tag: 'Belifood',
        owner: 'Beli',
        time: '10/2022',
        title: 'Website thương mại điện tử',
        subtitle:
          'Website kinh doanh các mặt hàng nông sản và sản phẩm cho sức khỏe',
        url: 'https://matongbeli.com',
      },
    ],
  };

  public selectedLanguage: string = getLocalLanguage();

  constructor(public translate: TranslateService) {}
  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  getListProduct() {
    return this.listProduct[this.selectedLanguage];
  }
}
