<div class="container-fluid pt-5 wow fadeInUp" data-wow-delay="0.03s">
  <div class="container pt-5">
    <div
      class="section-title text-center position-relative pb-3 mb-5 mx-auto"
      style="max-width: 600px"
    >
      <h5 class="fw-bold text-primary text-uppercase">
        {{ "service.title" | translate }}
      </h5>
      <h2 class="mb-0 text-uppercase">{{ serviceDetail.title }}</h2>
    </div>
    <div class="row g-3">
      <div class="col-lg-9">
        <div [innerHTML]="serviceDetail.html" class="text-justify"></div>
      </div>
      <div class="col-lg-3">
        <div *ngFor="let banner of getRandBanner()">
          <div class="banner-box">
            <img src="{{ banner }}" alt="banner image" class="banner-image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
