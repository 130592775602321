export const LOCAL_STORAGE_STATE = 'lang';

export const getLocalLanguage = () => {
  try {
    const state = localStorage.getItem(LOCAL_STORAGE_STATE);
    if (state) {
      return JSON.parse(state)['lang'];
    }
    return 'vi';
  } catch (error) {
    return 'vi';
  }
};

export const setLocalLanguage = (lang: string) => {
  localStorage.setItem(LOCAL_STORAGE_STATE, JSON.stringify({ lang: lang }));
};
