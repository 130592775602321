<!-- About Start -->
<div class="container-fluid pt-5 wow fadeInUp" data-wow-delay="0.05s">
  <div class="container pt-5">
    <div class="row g-5">
      <div class="col-lg-7">
        <div class="section-title position-relative pb-3 mb-5">
          <h5 class="fw-bold text-primary text-uppercase">
            {{ "about.title" | translate }}
          </h5>
          <h1 class="mb-0">
            {{ "about.description" | translate }}
          </h1>
        </div>
        <div class="mb-4" [innerHtml]="getDescription()"></div>
      </div>
      <div class="col-lg-5" style="min-height: 500px">
        <div class="position-relative h-100">
          <img
            class="position-absolute w-100 h-100 rounded wow zoomIn"
            data-wow-delay="0.5s"
            src="assets/img/about.jpg"
            style="object-fit: cover"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid pb-5 wow fadeInUp" data-wow-delay="0.05s">
  <div class="container py-5">
    <div class="row g-5">
      <div class="col-lg-7">
        <div class="row g-0 mb-3">
          <div class="col-sm-6 wow zoomIn" data-wow-delay="0.1s">
            <h5 class="mb-3">
              <i class="fa fa-check text-primary me-3"></i
              >{{ "about.award" | translate }}
            </h5>
            <h5 class="mb-3">
              <i class="fa fa-check text-primary me-3"></i
              >{{ "about.staff" | translate }}
            </h5>
          </div>
          <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
            <h5 class="mb-3">
              <i class="fa fa-check text-primary me-3"></i
              >{{ "about.support" | translate }}
            </h5>
            <h5 class="mb-3">
              <i class="fa fa-check text-primary me-3"></i
              >{{ "about.price" | translate }}
            </h5>
          </div>
        </div>
        <div
          class="d-flex align-items-center mb-4 wow fadeIn"
          data-wow-delay="0.3s"
        >
          <div
            class="bg-primary d-flex align-items-center justify-content-center rounded"
            style="width: 60px; height: 60px"
          >
            <i class="fa fa-phone-alt text-white"></i>
          </div>
          <div class="ps-4">
            <h5 class="mb-2">{{ "quote.call" | translate }}</h5>
            <h4 class="text-primary mb-0">{{ "phone.number" | translate }}</h4>
          </div>
        </div>
        <a
          href="quote"
          class="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
          data-wow-delay="0.5s"
          >{{ "quote.form.submit" | translate }}</a
        >
      </div>
      <div class="col-lg-5"></div>
    </div>
  </div>
</div>
<!-- About End -->
